.rbc-timeslot-group {
  min-height: 45px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  /* background-color: var(--primary-color-g3); */
  background-color: #204FD4 ;  
}

.rbc-calendar {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.rbc-toolbar {
  font-size: 14px;
}
.rbc-toolbar button {
  border-radius: 0.5rem;
}

.custom-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
}

.custom-toolbar button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-toolbar button:hover {
  background-color: #0056b3;
}

.toolbar-label {
  font-size: 1.2em;
  font-weight: bold;
}


.btn-calendar {
  border: 1px solid var(--primary-color-g3);
  background-color: var(--primary-color-g3);
  color: white;
  border-radius: 0.5rem;
  cursor: pointer;
  padding: 10px ;
  /* text-align: center; */
}

.btn-calendar-icon {
  font-size: 18px;
  font-weight: 600;
}
.btn-calendar-title {
  font-size: 12px;
  font-weight: 600;
}