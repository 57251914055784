:root {
  --pagination-active-bg: #1632D2 !important;
  --primary-color-g3: #1632D2;
  --primary-color-g3-500: #1632D2;
  --primary-color-g3-300: #204FD4;
  --secondary-color-g3: #204FD4;
  /* --border-color: rgb(160, 165, 223); */
  /* --border-color: rgb(198, 207, 234); */
  --border-color: #efeff2;
  /* --border-color: #d8dbe0; */
  /* --border-color: #fff; */

  --text-black-700: #2D3748;
  --text-black-500: #4A5568;
  --text-black-300: #718096;
  background-color: #f5f7fe;

  --bs-gutter-x: 1rem !important;

  --green-1: #35e65d;
  --grey-0: #808080;
  --grey-1: #cdd8ec;
  --grey-2: #f4f7f9;
  --grey-3: #b5b5b5;

  --color-info: #0dcaf0;
}

#root {
  background-color: #f5f7fe;
}

.row {
  --bs-gutter-x: 0.75rem;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #343A40 !important;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.bg-primary-secondary {
  /* background-color: var(--primary-color-g3) !important; */
  background-color: #204FD4 !important;
}

.text-black-700 {
  color: var(--text-black-700);
}

.text-black-500 {
  color: var(--text-black-500);
}

.text-black-300 {
  color: var(--text-black-300);
}

.bg-primary {
  background-color: var(--primary-color-g3) !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.badge-bg-primary {
  color: var(--primary-color-g3);
  background-color: #f4f6fe;
}

.badge-bg-success {
  background-color: #e6f9f3;
  color: #198754;
}

.badge-bg-danger {
  background-color: #fcf5f4;
  color: #9e4441;
  color: #dc3545;
}

.badge-bg-warning {
  background-color: #f6eed6;
  color: #856404;
}

.bg-appointment {
  color: var(--primary-color-g3);
  background-color: #f4f6fe;
}

.bg-follow-up {
  color: #17a2b8;
  background-color: #e3f7fa;
}

.bg-exam {
  color: #6c757d;
  background-color: #ebedef;
}

.bg-procedure {
  color: #198754;
  background-color: #e6f9f3;
}

.section-title {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary-color-g3);
}

.wrapper-box-white {
  border: 1px solid #e5e5e5;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

/* Override Bootstrap */
a {
  text-decoration: none !important;
  color: var(--primary-color-g3);
}

.form-control {
  /* height: 3rem; */
  height: 2.75rem;
  border-radius: 0.5rem;
  /* color: #505866; */
  color: #454e58;
  font-weight: 400;
  border: 1px solid var(--border-color);
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #475fe5;
  outline: 0;
  box-shadow: none;
  /* box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25); */
}


.input-group .form-control:focus+.input-group-text,
.input-group .form-control:focus~.input-group-text {
  border-color: #475fe5;
}

.input-group .input-group-text:first-child:focus-within,
.input-group:has(.form-control:focus) .input-group-text:first-child {
  border-color: #475fe5;
}

.css-16iv56l-singleValue {
  color: #454e58 !important;
  font-weight: 400 !important;
  ;
  font-family: inherit !important;
  ;
}

.css-13cymwt-control {
  border: 1px solid var(--border-color) !important;
  /* height: 3rem !important; */
  min-height: 2.75rem !important;
  border-radius: 0.5rem !important;
}

.css-b62m3t-container {
  padding: 0;
  /* height: 3rem; */
  min-height: 2.75rem;
  border-radius: 0.5rem
}

.input-group>.form-control:not(:focus).is-invalid,
.input-group>.form-floating:not(:focus-within).is-invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-control:not(:focus):invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.was-validated .input-group>.form-select:not(:focus):invalid {
  z-index: 0;
}

.btn {
  border-radius: 0.75rem;
  height: 2.8rem
}

.input-group-text {
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
}

.is-invalid {
  border-color: #dc3545 !important;
}

.btn-primary {
  background-color: var(--primary-color-g3) !important;
  color: #fff !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-weight: 500 !important;
}

.btn-primary-300 {
  background-color: var(--primary-color-g3-300) !important;
  color: #fff !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  font-weight: 500 !important;
}

.btn-secondary {
  background-color: transparent !important;
  color: var(--primary-color-g3) !important;
  border: 1px solid var(--primary-color-g3) !important;
}

.btn-secondary:hover {
  color: #FFF !important;
  background-color: var(--primary-color-g3) !important;
  border: 1px solid var(--primary-color-g3) !important;
}

.btn-danger {
  background-color: #CF0303;
}

.btn-danger-secondary {
  color: #CF0303;
  background-color: #FFF;
  border: 1px solid #CF0303;
}

.btn-danger-secondary:hover {
  color: #FFF;
  background-color: #CF0303;
  font-weight: 500;
  border: 1px solid #CF0303;
}

.btn-outline-info {
  border: 1px solid #0dcaf0;
}

.btn-outline-info:hover {
  color: #FFF;
}

.text-primary {
  color: var(--primary-color-g3) !important;
}

.text-secondary {
  color: #454e58 !important
}

.form-label {
  font-size: 15px !important;
  font-weight: 500;
  color: var(--text-black-500) !important;
}

/* Global styles */
.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}


.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.input-none:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #dee2e6 !important;
}

.border-left-0,
.border-left-0:focus {
  border-left: 0 !important;
}

.border-right-0,
.border-right-0:focus {
  border-right: 0 !important;
}

.border-top-0,
.border-top-0:focus {
  border-top: 0 !important;
}

.border-bottom-0,
.border-bottom-0:focus {
  border-bottom: 0 !important;
}

.rounded-025 {
  border-radius: 0.25rem !important;
}

.rounded-050 {
  border-radius: 0.5rem !important;
}

.rounded-075 {
  border-radius: 0.75rem !important;
}

.rounded-1 {
  border-radius: 1rem !important;
}

.rounded-125 {
  border-radius: 1.25rem !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.h-3 {
  /* height: 3rem !important; */
  height: 3rem !important;
}

.rounded-100 {
  border-radius: 100px;
}

.border-shadown {
  box-shadow: 0 10px 60px 5px rgb(0 0 0 / 9%);
}

/* SPINNER CSS */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 1rem;
  text-align: center;
  font-size: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.58039);
  height: 100%;
  width: 100%;
}

.ql-toolbar.ql-snow {
  border: 1px solid var(--border-color);
  border-radius: 0.5rem 0.5rem 0 0;
}

.ql-container.ql-snow {
  border: 1px solid var(--border-color);
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: white;
}

.ql-container {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: var(--bs-body-color);
  font-weight: 400;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}

.grecaptcha-badge {
  display: none;
}

.h1-g3 {
  font-size: 2rem;
  font-weight: 400;
  color: var(--primary-color-g3);
}

.h2-g3 {
  font-size: 1.75rem;
  font-weight: 400;
  color: var(--primary-color-g3);
}

.h3-g3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--primary-color-g3);
}

.h4-g3 {
  font-size: 1.25rem;
  font-weight: 400;
  color: var(--primary-color-g3);
}

.h5-g3 {
  font-size: 1rem;
  font-weight: 500;
  color: var(--primary-color-g3);
}

.height-25 {
  height: 25px !important;
}

.react-datepicker-popper {
  z-index: 9999;
  line-height: 0;
}

b,
strong {
  font-weight: 600;
}
