@import '../style.css';

.rcw-launcher {
  color: white;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-fill-mode: forwards;
  align-self: flex-end;
  background-color: var(--primary-color-g3);
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 2px 10px 1px var(--grey-3);
  height: 60px;
  margin-top: 10px;
  cursor: pointer;
  width: 60px;
}

.rcw-launcher:focus {
  outline: none;
}

.rcw-open-launcher {
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: rotation-rl;
  animation-fill-mode: forwards;
}

.rcw-close-launcher {
  width: 20px;
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: rotation-lr;
  animation-fill-mode: forwards;
  color: white;
}

@media screen and (max-width: 800px) {
  .rcw-launcher {
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: slide-in;
    animation-fill-mode: forwards;
    align-self: flex-end;
    background-color: var(--primary-color-g3);
    border: 0;
    border-radius: 50%;
    box-shadow: 0px 2px 10px 1px var(--grey-3);
    height: 60px;
    margin-top: 10px;
    cursor: pointer;
    width: 60px;
    display: none; /* @FIXME. Remove this line to show the launcher on mobile */
  }

  .rcw-hide-sm {
    display: none;
  }
}