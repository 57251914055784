.rcw-messages-container {
  background-color: #fff;
  height: 50vh;
  max-height: 410px;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}

.rcw-full-screen {}

.rcw-full-screen .rcw-messages-container {
  height: 100%;
  max-height: none;
}

.date-divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  font-size: 12px;
  color: #999;
  font-weight: bold;
  position: relative;
}

.date-divider span {
  background: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

@media screen and (max-width: 800px) {
  .rcw-messages-container {
    height: 100%;
    max-height: none;
  }
}