.line-dotted { 
  border-top: dotted 3px var(--primary-color-g3);
  font-weight: 500;
}
.wrapper-start-appointment {
  border: 1px solid #e5e5e5;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}