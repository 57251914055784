.rcw-message {
  margin: 10px;
  display: flex;
  white-space: pre-wrap;
  word-wrap: break-word;

  &-client {
    flex-direction: row-reverse;
  }
}

.rcw-timestamp {
  font-size: 10px;
  margin-top: 5px;
  color: var(--text-black-500) !important;
  font-weight: 400;
}

.rcw-client {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  color: white !important;
}

.rcw-client .rcw-message-text {
  background-color: var(--primary-color-g3);
  border-radius: 10px;
  max-width: 215px;
  padding: 15px;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.rcw-client .rcw-timestamp {
  align-self: flex-end;
}

.rcw-response {
  display: flex;
  flex-direction: column;
  align-items: flex-start;


}

.rcw-response .rcw-message-text {
  background-color: var(--grey-2);
  border-radius: 10px;
  max-width: 215px;
  padding: 15px;
  text-align: left;
}

.rcw-message-text p {
  margin: 0;
}

.rcw-message-text img {
  width: 100%;
  object-fit: contain;
}

.rcw-avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;

  &-client {
    margin: 0 0 0 10px;
  }
}