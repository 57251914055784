.bd-r {
  border-width: 0;
  border-right-width: 1px;
}

.bd-gray {
  border-style: solid;
  border-color: #ddd;
}

.dropdown-menu {
  box-shadow: 0 3px 6px rgba(0, 0, 0, .1607843137254902) !important;
}

.dropdown-menu-button {
  font-size: 14px;
  border: 1px solid var(--border-color);
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 6px rgba(0, 0, 0, .0607843137254902);
}
@media (max-width: 767px) {
  .bd-r {
    border-width: 0;
    border-bottom-width: 0;
  }
}