.rcw-widget-container {
  bottom: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 0;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 9999;
}

.rcw-full-screen {
  height: 100vh;
  margin: 0;
  max-width: none;
  width: 100%;
}

@media screen and (max-width: 800px) {
  .rcw-widget-container {
    height: 100%;
    margin: 0;
    max-width: none;
    width: 100%;
    bottom: 10px;
    right: 10px;
  }
}

.rcw-previewer .rcw-message-img {
  cursor: pointer;
}

.rcw-close-widget-container {
  height: max-content;
  width: max-content;
}

@keyframes rotation-lr {
  from {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes rotation-rl {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(10px);
  }
}