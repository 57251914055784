.line-section-dark {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  border-top: 2px dotted var(--primary-color-g3);
  margin: 20px 0;
}

.line-text-dark {
  position: absolute;
  padding: 3px 12px; 
  background-color: var(--primary-color-g3); 
  color: #fff; 
  font-size: 16px; 
  white-space: nowrap;
  border-radius: 50px;
}


.line-section-light {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  border-top: 1px solid var(--primary-color-g3);
  margin: 20px 0;
}

.line-text-light {
  position: absolute;
  padding: 3px 12px; 
  font-weight: 500;
  background-color: #fff; 
  color:  var(--primary-color-g3); 
  font-size: 22px; 
  white-space: nowrap;
  border-radius: 50px;
}
