.text-prefix-otp {
  color: var(--primary-color-g3);
  font-size: 25px;
  font-weight: bold;
  align-self: center;
}

.btn-resend-code { 
  color: var(--primary-color-g3) !important;
  font-size: 16px;
  cursor: pointer;
}