.g3-input-file {
  text-align: center;
  position: relative;
  padding: 1rem;
}

.g3-input-file .drop-area {
  padding: 1rem;
  border: 2px dashed #ccc;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.bd-radius {
  border-radius: 5px;
}
.g3-item-attachment {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  margin-top: 1rem;
}
.g3-preview-img {
  border-radius: 0.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;

}
.g3-list-attachments {
  display: flex;
  flex-wrap: wrap;
}
.g3-input-file img {
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
}