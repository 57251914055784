.prescription-header-info {
  border-radius: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  background-color: #fff;
}

.prescription-header-top {
  border-radius: 1rem 1rem 0  0;
  height: 45px;
  background-color: #204FD4;
}

.prescription-content-data {
  display: flex;
  flex-wrap: wrap;
}
.prescription-content-img {
  position: relative;
  top: -20px
}

.prescription-header-content {
  padding:  0 1rem 1rem 1rem;
}

.prescription-content-label {
  flex-wrap: wrap;
  color: var(--text-black-300);
  width: 100%;
  font-size: 15px;
}

.prescription-content-text {
  flex-wrap: wrap;
  color: var(--text-black-700);
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}
