.breadcrumb-item {
  color: var(--primary-color-g3);
  font-weight: 400;
  font-size: 19px;
}
.text-user-name {
  color: #454e58;
} 

.dropdown-menu {
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #454e58;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
  box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
    0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-weight: 400;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  font-size: 16px !important;
  /* color: white ; */
  font-weight: 400;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-nav .active {
    color: white !important;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: white;
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: white !important;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: white;
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.95);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.65);
  color: white;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.95);
  border-color: transparent;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.95%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.95);
}
.navbar-dark .navbar-text a {
  color: rgba(255, 255, 255, 0.65);
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: rgba(255, 255, 255, 0.65);
}

.navbar-vertical.navbar-expand-xs.fixed-left + .main-content {
  margin-left: 250px;
}

.navbar-vertical.navbar-expand-xs.fixed-right + .main-content {
  margin-right: 250px;
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-left + .main-content {
    margin-left: 250px;
  }
  .navbar-vertical.navbar-expand-sm.fixed-right + .main-content {
    margin-right: 250px;
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-left + .main-content {
    margin-left: 230px;
  }
  .navbar-vertical.navbar-expand-md.fixed-right + .main-content {
    margin-right: 230px;
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-left + .main-content {
    margin-left: 230px;
  }
  .navbar-vertical.navbar-expand-lg.fixed-right + .main-content {
    margin-right: 230px;
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-left + .main-content {
    margin-left: 230px;
  }
  .navbar-vertical.navbar-expand-xl.fixed-right + .main-content {
    margin-right: 230px;
  }
}

.nav-wrapper {
  padding: 1rem 0;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.nav-wrapper + .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}


.nav-link i.ni {
  position: relative;
  top: 2px;
}
.nav-item > .active {
  background: var(--primary-color-g3);
  font-weight: 500;
  border-radius: 2rem;
  color: white;
}

@media (max-width: 767.98px) {
  .nav-item > .nav-link {
    color: var(--primary-color-g3) !important;
  }

  .nav-item > .active {
    background: var(--primary-color-g3) !important;
    font-weight: 500;
    border-radius: 2rem;
    color: white !important;
  }
}

@media (min-width: 992px) {
  .nav-item.active-pro {
    position: absolute;
    width: 100%;
    bottom: 10px;
    background: #f6f9fc;
  }
}

.nav-pills .nav-item:not(:last-child) {
  padding-right: 1rem;
}

.nav-pills .nav-link {
  padding: 0.75rem 1rem;
  color: #5e72e4;
  font-weight: 500;
  font-size: 0.875rem;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  transition: all 0.15s ease;
}

.nav-item:hover {
  /* background: #1632D2;
  border-radius: 2rem; */
}

.nav-pills .nav-link:hover {
  color: #485fe0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5e72e4;
}

@media (max-width: 575.98px) {
  .nav-pills .nav-item {
    margin-bottom: 1rem;
  }
}

@media (max-width: 767.98px) {
  .nav-pills:not(.nav-pills-circle) .nav-item {
    padding-right: 0;
  }
}

.nav-pills-circle .nav-link {
  text-align: center;
  height: 60px;
  width: 60px;
  padding: 0;
  line-height: 60px;
  border-radius: 50%;
}

.nav-pills-circle .nav-link-icon i,
.nav-pills-circle .nav-link-icon svg {
  font-size: 1rem;
}

.navbar-horizontal .navbar-nav .nav-link {
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: normal;
  letter-spacing: 0;
  transition: all 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-horizontal .navbar-nav .nav-link {
    transition: none;
  }
}
.navbar-horizontal .navbar-nav .nav-link .nav-link-inner--text {
  margin-left: 0.25rem;
}

.navbar-horizontal .navbar-brand {
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05px;
}
.navbar-horizontal .navbar-brand img {
  height: 30px;
}

.navbar-horizontal .navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-horizontal .navbar-light .navbar-brand {
  color: #32325d;
}

.navbar-horizontal .navbar-nav .nav-item .media:not(:last-child) {
  margin-bottom: 1.5rem;
}

@media (min-width: 992px) {
  .navbar-horizontal .navbar-nav .nav-item {
    margin-right: 0.5rem;
  }
  .navbar-horizontal .navbar-nav .nav-item [data-toggle="dropdown"]::after {
    transition: all 0.15s ease;
  }
  .navbar-horizontal
    .navbar-nav
    .nav-item.show
    [data-toggle="dropdown"]::after {
    transform: rotate(180deg);
  }
  .navbar-horizontal .navbar-nav .nav-link {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0.375rem;
  }
  .navbar-horizontal .navbar-nav .nav-link i {
    margin-right: 0.625rem;
  }
  .navbar-horizontal .navbar-nav .nav-link-icon {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-size: 1rem;
    border-radius: 0.375rem;
  }
  .navbar-horizontal .navbar-nav .nav-link-icon i {
    margin-right: 0;
  }
}

.navbar-horizontal .navbar-transparent {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  border: 0;
  box-shadow: none;
}
.navbar-horizontal .navbar-transparent .navbar-brand {
  color: white;
}
.navbar-horizontal .navbar-transparent .navbar-toggler {
  color: white;
}
.navbar-horizontal .navbar-transparent .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.95)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

@media (min-width: 768px) {
  .navbar-horizontal .navbar-transparent .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.95);
  }
  .navbar-horizontal .navbar-transparent .navbar-nav .nav-link:hover,
  .navbar-horizontal .navbar-transparent .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.65);
  }
  .navbar-horizontal .navbar-transparent .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  .navbar-horizontal .navbar-transparent .navbar-nav .show > .nav-link,
  .navbar-horizontal .navbar-transparent .navbar-nav .active > .nav-link,
  .navbar-horizontal .navbar-transparent .navbar-nav .nav-link.show,
  .navbar-horizontal .navbar-transparent .navbar-nav .nav-link.active {
    color: rgba(255, 255, 255, 0.65);
  }
  .navbar-horizontal .navbar-transparent .navbar-brand {
    color: rgba(255, 255, 255, 0.95);
  }
  .navbar-horizontal .navbar-transparent .navbar-brand:hover,
  .navbar-horizontal .navbar-transparent .navbar-brand:focus {
    color: rgba(255, 255, 255, 0.95);
  }
}

.navbar-vertical {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
}
.navbar-vertical .navbar {
  border-width: 0 0 1px 0;
  border-style: solid;
}
.navbar-vertical .navbar-light {
  background-color: transparent;
  border-color: #f6f9fc;
}
.navbar-vertical .navbar-brand {
  margin-right: 0;
}
.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-width: 100%;
  max-height: 2rem;
}
@media (min-width: 768px) {
  .navbar-vertical .navbar-collapse {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .navbar-vertical .navbar-collapse:before {
    content: "";
    display: block;
    margin: 1rem -1rem;
  }
}
.navbar-vertical .navbar-nav {
  margin-left: -1rem;
  margin-right: -1rem;
}
.navbar-vertical .navbar-nav .nav-link {
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */

  margin: 0 !important;
  font-size: 0.9rem;
}
.navbar-vertical .navbar-nav .nav-link.active {
  position: relative;
}
.navbar-vertical .navbar-nav .nav-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25rem;
  bottom: 0.25rem;
  border-left: none;
}
.navbar-vertical .navbar-nav .nav-link > i {
  min-width: 2.25rem;
  font-size: 0.9375rem;
  line-height: 1.5rem;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu {
  border: none;
}
.navbar-vertical .navbar-nav .nav-link .dropdown-menu .dropdown-menu {
  margin-left: 0.75rem;
}
.navbar-vertical .navbar-nav .nav-link {
  display: flex;
  align-items: center;
}
.navbar-vertical .navbar-nav .nav-link[data-toggle="collapse"]:after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  content: "\f105";
  margin-left: auto;
  color: #8898aa;
  transition: all 0.15s ease;
}
.navbar-vertical
  .navbar-nav
  .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
  transform: rotate(90deg);
}
.navbar-vertical .navbar-nav .nav .nav-link {
  padding-left: 3.25rem;
}
.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 3.75rem;
}
.navbar-vertical .navbar-heading {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.navbar-vertical.navbar-expand-xs {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 250px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow-y: auto;
}
.navbar-vertical.navbar-expand-xs > [class*="container"] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-xs > [class*="container"] {
    min-height: none;
    height: 100%;
  }
}
.navbar-vertical.navbar-expand-xs.fixed-left {
  left: 0;
  border-width: 0 1px 0 0;
}
.navbar-vertical.navbar-expand-xs.fixed-right {
  right: 0;
  border-width: 0 0 0 1px;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-collapse > * {
  min-width: 100%;
}
.navbar-vertical.navbar-expand-xs .navbar-nav {
  flex-direction: column;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: 0.65rem 1.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link.active:before {
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: auto;
  border-left: none;
  border-bottom: 0;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
  padding-left: 3.75rem;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav-link {
  padding-left: 4.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-brand {
  display: block;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.navbar-vertical.navbar-expand-xs .navbar-brand-img {
  max-height: 2.5rem;
}
.navbar-vertical.navbar-expand-xs .navbar-user {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-top: 1px solid #e9ecef;
}
.navbar-vertical.navbar-expand-xs .navbar-user .dropup .dropdown-menu {
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 250px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: auto;
  }
  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (min-width: 576px) and (-ms-high-contrast: none),
  (min-width: 576px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-sm > [class*="container"] {
    min-height: none;
    height: 100%;
  }
}
@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-left {
    left: 0;
    border-width: 0 1px 0 0;
  }
  .navbar-vertical.navbar-expand-sm.fixed-right {
    right: 0;
    border-width: 0 0 0 1px;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
    padding: 0.65rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link.active:before {
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0;
    right: auto;
    border-left: none;
    border-bottom: 0;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link {
    padding-left: 3.75rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav-link {
    padding-left: 4.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand-img {
    max-height: 2.5rem;
  }
  .navbar-vertical.navbar-expand-sm .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-top: 1px solid #e9ecef;
  }
  .navbar-vertical.navbar-expand-sm .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 231px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: auto;
  }
  .navbar-vertical.navbar-expand-md > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (min-width: 768px) and (-ms-high-contrast: none),
  (min-width: 768px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-md > [class*="container"] {
    min-height: none;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-left {
    left: 0;
    border-width: 0 1px 0 0;
  }
  .navbar-vertical.navbar-expand-md.fixed-right {
    right: 0;
    border-width: 0 0 0 1px;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding: 0.65rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0;
    right: auto;
    border-left: none;
    border-bottom: 0;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link {
    padding-left: 3.75rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav-link {
    padding-left: 4.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 2.5rem;
  }
  .navbar-vertical.navbar-expand-md .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-top: 1px solid #e9ecef;
  }
  .navbar-vertical.navbar-expand-md .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 250px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: auto;
  }
  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (min-width: 992px) and (-ms-high-contrast: none),
  (min-width: 992px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-lg > [class*="container"] {
    min-height: none;
    height: 100%;
  }
}
@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-left {
    left: 0;
    border-width: 0 1px 0 0;
  }
  .navbar-vertical.navbar-expand-lg.fixed-right {
    right: 0;
    border-width: 0 0 0 1px;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0.65rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active:before {
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0;
    right: auto;
    border-left: none;
    border-bottom: 0;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link {
    padding-left: 3.75rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav-link {
    padding-left: 4.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand-img {
    max-height: 2.5rem;
  }
  .navbar-vertical.navbar-expand-lg .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-top: 1px solid #e9ecef;
  }
  .navbar-vertical.navbar-expand-lg .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 250px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: auto;
  }
  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
@media all and (min-width: 1200px) and (-ms-high-contrast: none),
  (min-width: 1200px) and (-ms-high-contrast: active) {
  .navbar-vertical.navbar-expand-xl > [class*="container"] {
    min-height: none;
    height: 100%;
  }
}
@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-left {
    left: 0;
    border-width: 0 1px 0 0;
  }
  .navbar-vertical.navbar-expand-xl.fixed-right {
    right: 0;
    border-width: 0 0 0 1px;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse > * {
    min-width: 100%;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
    padding: 0.65rem 1.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0;
    right: auto;
    border-left: none;
    border-bottom: 0;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link {
    padding-left: 3.75rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav-link {
    padding-left: 4.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand-img {
    max-height: 2.5rem;
  }
  .navbar-vertical.navbar-expand-xl .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-top: 1rem;
    padding-bottom: 0rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-top: 1px solid #e9ecef;
  }
  .navbar-vertical.navbar-expand-xl .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) {
  .navbar .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    margin: 0;
  }
  .navbar .dropdown-menu-arrow:before {
    background: #fff;
    box-shadow: none;
    content: "";
    display: block;
    height: 12px;
    width: 12px;
    left: 20px;
    position: absolute;
    bottom: 100%;
    transform: rotate(-45deg) translateY(12px);
    z-index: -5;
    border-radius: 2px;
  }
  .navbar .dropdown-menu-right:before {
    right: 20px;
    left: auto;
  }
  .navbar:not(.navbar-nav-hover) .dropdown-menu.show {
    opacity: 1;
    pointer-events: auto;
    animation: show-navbar-dropdown 0.25s ease forwards;
  }
  .navbar:not(.navbar-nav-hover) .dropdown-menu.close {
    display: block;
    animation: hide-navbar-dropdown 0.15s ease backwards;
  }
  .navbar.navbar-nav-hover .dropdown-menu {
    opacity: 0;
    display: block;
    pointer-events: none;
    transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
    transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
  }
  .navbar.navbar-nav-hover .nav-item.dropdown:hover > .dropdown-menu {
    display: block;
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translate(0, 0);
    animation: none;
  }
  .navbar .dropdown-menu-inner {
    position: relative;
    padding: 1rem;
  }
  @keyframes show-navbar-dropdown {
    0% {
      opacity: 0;
      transform: translate(0, 10px) perspective(200px) rotateX(-2deg);
      transition: visibility 0.25s, opacity 0.25s, transform 0.25s;
    }
    100% {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
  @keyframes hide-navbar-dropdown {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      transform: translate(0, 10px);
    }
  }
}

.navbar-collapse-header {
  display: none;
}

@media (max-width: 767.98px) {
  .navbar-nav .nav-link {
    padding: 0.625rem 0;
    color: #172b4d ;
  }
  .navbar-nav .dropdown-menu {
    box-shadow: none;
    min-width: auto;
  }
  .navbar-nav .dropdown-menu .media svg {
    width: 30px;
  }
  .navbar-collapse {
    width: calc(100% - 1.4rem);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    margin: 0.7rem;
    overflow-y: auto;
    height: auto !important;
    opacity: 0;
  }
  .navbar-collapse .navbar-toggler {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 0;
  }
  .navbar-collapse .navbar-toggler span {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    opacity: 1;
    background: #283448;
  }
  .navbar-collapse .navbar-toggler :nth-child(1) {
    transform: rotate(135deg);
  }
  .navbar-collapse .navbar-toggler :nth-child(2) {
    transform: rotate(-135deg);
  }
  .navbar-collapse .navbar-collapse-header {
    display: block;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-collapse .collapse-brand img {
    height: 36px;
  }
  .navbar-collapse .collapse-close {
    text-align: right;
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    padding: 1.5rem;
    border-radius: 0.375rem;
    background: #fff;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
      0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    animation: show-navbar-collapse 0.2s ease forwards;
  }
  .navbar-collapse.collapsing-out {
    animation: hide-navbar-collapse 0.2s ease forwards;
  }
}

@keyframes show-navbar-collapse {
  0% {
    opacity: 0;
    transform: scale(0.95);
    transform-origin: 100% 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes hide-navbar-collapse {
  from {
    opacity: 1;
    transform: scale(1);
    transform-origin: 100% 0;
  }
  to {
    opacity: 0;
    transform: scale(0.95);
  }
}