.rcw-conversation-container {
  border-radius: 10px;
  box-shadow: 0px 2px 10px 1px var(--grey-3);
  min-width: 370px;
  /* max-width: 100px;; */
  /* max-width: 90vw; */
  position: relative;
}

.rcw-conversation-container.active {
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.rcw-conversation-container.hidden {
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.emoji-mart-preview {
  display: none;
}

.rcw-full-screen .rcw-conversation-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}