.rcw-chat-list-item { 
  max-width: 100%;
  display: flex;
  justify-items: center;
  padding: 0 1rem;
  cursor: pointer;
  
  /* border: 1px solid var(--grey-1); */
}

.rcw-chat-list-item .rcw-chat-list-content {
  /* display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-left: 1rem; */

  display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%; /* Garante que a altura do pai seja respeitada */
    margin-left: 1rem;
}

.rcw-chat-list-item-message { 
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; 
  /* max-width: 100%; */
  font-size: 15px;
  max-width: 250px;
  display: inline-block;
}

.rcw-chat-list-item-notification > i{
  /* width: 5px;
  height: 5px; */
  /* background-color: var(--primary-color-g3); */
  /* align-items: center;
  justify-content: center; */
  color: var(--primary-color-g3);;
  /* bg-emerald-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs */
}