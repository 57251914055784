.rcw-launcher-new-chat {
  animation-delay: 0;
  animation-duration: 0.5s;
  animation-name: slide-in;
  animation-fill-mode: forwards;
  align-self: flex-end;
  background-color: var(--color-info);
  color: white;
  border: 0;
  border-radius: 50%;
  box-shadow: 0px 2px 10px 1px var(--grey-3);
  height: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 50px;
  position:absolute;
  bottom: 10px;
  right: 20px;
}

.rcw-chat-list-wrapper {
  background-color: #fff;
  position: relative; 
  height: 50vh;
  max-height: 410px;
  overflow-y: scroll;
  padding-top: 10px;
  -webkit-overflow-scrolling: touch;
}
