.estimated-return-content-label {
  flex-wrap: wrap;
  color: var(--text-black-300);
  width: 100%;
  font-size: 15px;
}

.estimated-return-content-text {
  flex-wrap: wrap;
  color: var(--text-black-700);
  width: 100%;
  font-size: 15px;
  font-weight: 500;
}