.custom-card { 
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: 1rem;
  box-shadow: 0 10px 60px 5px rgb(0 0 0 / 9%);
  padding: 1rem;
}

.custom-card-icon {
  width: 48px;
  height: 48px;
  background-position: 50%;
  border-radius: 50%;
  text-align: center;
  display: inline-block;
  color: #FFF;
  /* background-image: linear-gradient(310deg, #5e72e4, #825ee4); */
  background-image: linear-gradient(310deg, #5e72e4, var(--primary-color-g3));
  /* background-image: linear-gradient(310deg, var(--primary-color-g3), var(--primary-color-g3)); */
}

.custom-card-icon i {
  top: 14px;
  position: relative;
}