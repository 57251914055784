
.close {
  transition: all 0.15s ease;
}
.close > span:not(.sr-only) {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  line-height: 17px;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  font-size: 1.25rem;
  display: block;
  transition: all 0.15s ease;
}
.close:hover,
.close:focus {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.9);
  outline: none;
}
.close:hover span:not(.sr-only),
.close:focus span:not(.sr-only) {
  background-color: transparent;
}

.main-content {
  position: relative;
}
.main-content .navbar-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  /* background-color: transparent; */
  padding-left: 0 !important;
  padding-right: 0 !important;
  -webkit-box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
  box-shadow: 0 3px 6px rgba(0,0,0,.1607843137254902);
  background-color: white;
  height: 70px;

  border-radius: 0 0 1.5rem 1.5rem;
}

.main-content .main-wrapper {
  padding-top: 80px;
  padding-left: 1rem;
  padding-right: 1rem;
  min-height: 100vh;
  /* background-color: #f9fbfc; */
  /* background-color: #f1f3f6; */
  background-color: #f5f7fe;
}
@media (max-width: 767.98px) {
  .main-content .navbar-top {
    display: none;;
  }

  .main-content .main-wrapper {
    padding-top: 0px;
    padding-left: .25rem;
    padding-right: .25rem;
    min-height: 100vh;
  }
  
}
@media (min-width: 768px) {
  .main-content .container-fluid,
  .main-content .container-sm,
  .main-content .container-md,
  .main-content .container-lg,
  .main-content .container-xl {
    padding-left: 39px !important;
    padding-right: 39px !important;
  }
}

.title-section {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0px;
  color:#4a5568;
}

.float-button-whatsapp{
	position:fixed;
	width: 50px;
	height: 50px;
	bottom: 15px;
	right: 25px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float-button-whatsapp{
	margin-top:16px;
}