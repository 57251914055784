.custom-table {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.custom-table table {
  border-collapse: separate;
  border-spacing: 0 9px;
  max-height: 40vh
}


/* .custom-table table tr th {
  background-color: #f9fbfc;;
} */
/* .custom-table table .icon-action,.custom-table table thead tr th:last-child {
  text-align: right
} */

.custom-table table .perfilAcesso {
  text-align: left
}

.custom-table table thead th {
  border-bottom-width: 0!important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  /* color: #454e58 !important; */
  /* color: #5a5a6c !important; */
  color: var(--primary-color-g3);
  background-color: transparent !important;;
}

.custom-table table tr {
  transition: .2s;
  border-collapse: collapse;
  border-radius: 0.5rem;
}

.custom-table table tbody tr:hover {
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  border-collapse: collapse
}

.custom-table table td {
  /* color: #343A40; */
  /* color: #505866; */
  border: 1px solid var(--border-color);
  border-radius: none;
  border-style: solid none;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: #454e58;
  line-height: 3vh;
  padding-top: 15px;
  padding-bottom: 16px;
}

.custom-table td {
  max-width: 25ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.custom-table table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.custom-table table td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.custom-table table th:first-child {
  padding-left: 16px
}

.custom-table table th:last-child {
  padding-right: 16px
}

.custom-table table td:first-child {
  padding-left: 16px
}

.custom-table table .td-space-left {
  padding-left: 16px!important
}

.custom-table table .td-space-right {
  padding-right: 16px!important
}

.custom-table td:first-child {
  padding-right: 50px
}

.custom-table-toggle {
  font-size: 20px;
  width: fit-content;
  cursor: pointer;;
  border: 1px solid #e6e7ec;
  border-radius: 0.5rem;
  padding: 0.5rem 0;
}

.table-toggle-item {
  display: inline;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0;
}

.table-toggle-active {
  background-color: #1632D2 !important;
  color: white;
  border-radius: 0.5rem;
} 

.custom-table-card-item {
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 0.5rem;
  width: 100%;
  background-color: white;
}

.custom-table-card-item:hover { 
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  border-collapse: collapse;
}
.table-card-row {
  margin-bottom: 1rem;
}

.table-card-title {
  font-weight: 500;
  width: 100%;
  color: var(--primary-color-g3);

  font-weight: 400;
  font-size: 15px;
}

.table-card-value {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  color: #454e58;
}
