.rcw-conversation-container .rcw-header {
  background-color: var(--primary-color-g3);
  border-radius: 10px 10px 0 0;
  color: #FFF;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 0 25px;
}

.rcw-conversation-container .rcw-title {
  font-size: 19px;
  margin: 0;
  padding: 0px 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.rcw-conversation-container .rcw-close-button {
  display: none;
}

.rcw-conversation-container .avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
  vertical-align: middle;
}

.rcw-full-screen .rcw-header {
  border-radius: 0;
  flex-shrink: 0;
  position: relative;
}

.rcw-close {
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  /* color: var(--bs-btn-close-color); */
  color: yellow;
  background: transparent var(--bs-btn-close-bg) center / 1em auto no-repeat;
  border: 0;
  border-radius: .375rem;
  opacity: var(--bs-btn-close-opacity);
}

.rcw-full-screen .rcw-close-button {
  background-color: var(--primary-color-g3);
  border: 0;
  display: block;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 40px;
}

.rcw-full-screen .rcw-close {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 800px) {
  .rcw-conversation-container .rcw-header {
    border-radius: 0;
    flex-shrink: 0;
    position: relative;
  }

  .rcw-conversation-container .rcw-close-button {
    background-color: var(--primary-color-g3);
    border: 0;
    display: block;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
  }

  .rcw-conversation-container .rcw-close {
    width: 20px;
    height: 20px;
  }
}