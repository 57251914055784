

.submenu-item {
  background-color: var(--secondary-color-g3) !important; /* Background color for all SubMenu items */
  color: white !important; /* Text color for all SubMenu items */
}

.submenu-label {
  background-color: blue !important; /* Background color for SubMenu label */
  color: white !important; /* Text color for SubMenu label */
}

.ps-menu-button:hover {
  background: var(--primary-color-g3) !important;
  font-weight: 500;
  border-radius: 2rem;
  color: white;
}


.ps-menuitem-root .ps-active {
  background: var(--primary-color-g3) !important;
  font-weight: 500;
  border-radius: 2rem;
  color: white;
}

.ps-sidebar-root {
  min-width: 100% !important;
  /* max-width: 100% !important; */
  border: none !important;
  max-width: -webkit-fill-available;
}

.ps-submenu-content {
  background-color: transparent !important;
}

.ps-menu-label > .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ps-menu-button:has(.nav-link.active) {
  background: var(--primary-color-g3) !important;
  font-weight: 500;
  border-radius: 2rem;
  color: white;
}



@media (max-width: 767.98px) {
  .navbar-collapse.collapsing, .navbar-collapse.show {
  
      color: var(--primary-color-g3) !important;
  }

  .ps-sidebar-root {
    color:  var(--primary-color-g3);
  }
  .ps-sidebar-container {
    color:  var(--primary-color-g3);
  }

  .navbar-light .navbar-nav .nav-link {
    color: var(--primary-color-g3);
  }

  /* .ps-menu-button .ps-open {
    color: white;
  } */

  .ps-menu-button:hover {
    background: var(--primary-color-g3) !important;
    font-weight: 500;
    border-radius: 2rem;
    color: white !important;
}
}
