.custom-tab-header { 
  display: flex;
  flex-wrap: wrap;
}

.custom-tab-item { 
  color: var(--primary-color-g3);
  font-size: 16px;
  font-weight: 400;
  padding: 1rem;
  cursor: pointer;
}

.custom-tab-item.active {
  font-weight: 500;
  border-bottom: 2px solid var(--primary-color-g3);
}

.custom-tab-content {
  /* margin-top: 2rem; */
}

.rbc-toolbar button {
  border: 1px solid var(--primary-color-g3) !important;
  color: var(--primary-color-g3) !important;
  border-radius: 5px ;
}



.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  color: white !important;
  background-color: #204FD4 !important;
  border: 1px solid #204FD4 !important;
}