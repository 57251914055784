.box-medical-record-history {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.medical-record-section-title {
    font-size: 22px;
    font-weight: 500;
    color: var(--primary-color-g3);
    margin-bottom: 0.5rem;
}

.medical-record-history-row {
  margin-bottom: 0.5rem;
}

.medical-record-history-title {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-black-00);
    flex-wrap: wrap;
}

.medical-record-history-value {
    font-size: 15px;
    color: var(--text-black-500);
    flex-wrap: wrap;
}